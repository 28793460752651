import React from "react"
import { PageProps } from "gatsby"
import { Typography } from "../components/Typography/Typography"
import { BasePageData, withLayout } from "../components/Layout/layout.hocs"
import styled from "@emotion/styled"
import { Paper } from "../components/Paper/Paper"
import { ImageDataLike, GatsbyImage } from "gatsby-plugin-image"
import { CaseStudy } from "../FeaturedCaseStudies/FeaturedCaseStudies"
import { getFilteredFeatures, getSlugFromTitle } from "../utils/slug"
import { breakpoint } from "../theme/theme"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { PrimaryButton } from "../components/PrimaryButton/PrimaryButton"
import { CaseStudyCard } from "../components/CaseStudyCard/CaseStudyCard"
import {
  getCaseStudies,
  getInsights,
  getLandingImage,
  getServiceCards,
} from "./home.utils"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"
import { Button } from "../components/Button/Button"
import { NewsletterCard } from "../components/NewsletterCard/NewsletterCard"
import { BlogSummaryCard } from "../components/BlogSummary/BlogSummaryCard"
import { ServicePreview } from "../components/ServicePreview/ServicePreview"

interface HomeData extends BasePageData {
  homePage: {
    frontmatter: {
      title: string
      subtitle: string
      buttonText: string
      landingImage: ImageDataLike
      landingServices: {
        caption: string
        title: string
        serviceCard: Array<{
          icon: ImageDataLike
          title: string
          description: string
          link: string
          linkText: string
        }>
      }
      newsletter: {
        sideImage: ImageDataLike
      }
      caseStudies: {
        caption: string
        title: string
        featuredCaseStudies: Array<string>
      }
      insight: {
        caption: string
        title: string
        featuredInsights: Array<string>
      }
    }
  }
  caseStudies: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: CaseStudy
    }>
  }
  insights: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: {
        title: string
        category: string
        summary: string
        featuredImage: ImageDataLike
      }
    }>
  }
}

type HomeProps = PageProps<HomeData>

export const Home: React.FC<HomeProps> = withLayout(
  { title: "Home", variant: "dark" },
  (props) => {
    const {
      title,
      subtitle,
      landingImage,
      buttonText,
      landingServices,
      caseStudies,
      insight,
      newsletter,
    } = props.data.homePage.frontmatter
    const featuredCaseStudies =
      props.data.homePage.frontmatter.caseStudies.featuredCaseStudies
    const processedCaseStudies = getCaseStudies(
      getFilteredFeatures(featuredCaseStudies, props.data.caseStudies.nodes)
    )

    const processedInsights = getInsights(
      getFilteredFeatures(insight.featuredInsights, props.data.insights.nodes)
    )

    const processedLandingImage = getLandingImage(landingImage)
    const processedServiceCards = getServiceCards(landingServices.serviceCard)
    const newsletterImage = getLandingImage(newsletter.sideImage)
    return (
      <>
        <StyledPaper color="blue" gradient>
          <HeroContainer>
            <HeroTextContent>
              <HeroTitle variant="h1">{title}</HeroTitle>
              <HeroSubtitle variant="h5">{subtitle}</HeroSubtitle>
              <PlainLink to="/contact">
                <LandingButton>{buttonText}</LandingButton>
              </PlainLink>
            </HeroTextContent>
            <LandingImageContainer>
              <GatsbyImage image={processedLandingImage} alt={title} />
            </LandingImageContainer>
          </HeroContainer>
        </StyledPaper>
        <MainContainer>
          <LandingServicesContainer color="darkblue" gradient>
            <ServicesTextContainer>
              <div>
                <ServicesCaption>
                  <CaptionText variant="h6">
                    {landingServices.caption}
                  </CaptionText>
                  <AccentDivider />
                </ServicesCaption>
              </div>
              <LandingServicesTitle variant="h2">
                {landingServices.title}
              </LandingServicesTitle>
            </ServicesTextContainer>
          </LandingServicesContainer>
          <HomeServices>
            {processedServiceCards.map((service) => (
              <ServicePreview {...service} key={service.title} />
            ))}
          </HomeServices>
          <CaseStudiesContainer>
            <CaseStudiesTitle>
              <ServicesCaption>
                <CaptionText variant="h6">{caseStudies.caption}</CaptionText>
                <AccentDivider />
              </ServicesCaption>
              <Typography variant="h2">{caseStudies.title}</Typography>
            </CaseStudiesTitle>
            {processedCaseStudies.map(
              ({ client, caption, color, coverImage, title }, index) => (
                <CaseStudyCard
                  company={client}
                  color={color}
                  subtitle={caption}
                  coverImage={coverImage}
                  title={title}
                  key={`${client}-${index}`}
                />
              )
            )}
          </CaseStudiesContainer>
          <InsightsSection color="darkblue">
            <InsightsTitleSection>
              <InsightsTitle>
                <ServicesCaption>
                  <CaptionText variant="h6">{insight.caption}</CaptionText>
                  <AccentDivider />
                </ServicesCaption>
                <Typography variant="h2">{insight.title}</Typography>
              </InsightsTitle>
              <PlainLink to="/insights">
                <Button variant="outlined">View All</Button>
              </PlainLink>
            </InsightsTitleSection>
            <InsightsContainer>
              {processedInsights.map(
                ({ title, category, summary, featuredImage }) => {
                  const link = `insights/${getSlugFromTitle(title)}`

                  return (
                    <BlogSummaryCard
                      key={title}
                      title={title}
                      category={category}
                      description={summary}
                      coverImage={featuredImage}
                      link={link}
                      darkBlueBg={true}
                    />
                  )
                }
              )}
            </InsightsContainer>
          </InsightsSection>
          <NewsletterSection>
            <NewsletterCard sideImage={newsletterImage} />
          </NewsletterSection>
        </MainContainer>
      </>
    )
  }
)

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const LandingButton = styled(PrimaryButton)({
  width: "100%",
  [breakpoint("md")]: {
    width: "auto",
  },
})

const StyledPaper = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  width: "100%",
  boxSizing: "border-box",
})

const HeroContainer = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "4em",
  padding: "6em 2em",
  [breakpoint("sm")]: {
    gridTemplateColumns: "1fr 1fr",
    padding: "10em 2em",
  },
})

const HeroTextContent = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  color: "#fff",
  justifyContent: "center",
})

const HeroTitle = styled(Typography)({
  fontSize: "36px",
  [breakpoint("md")]: {
    fontSize: "40px",
  },
  [breakpoint("lg")]: {
    fontSize: "64px",
  },
})
const HeroSubtitle = styled(Typography)({
  fontWeight: 400,
  lineHeight: "32px",
})

const LandingImageContainer = styled("div")({
  minHeight: "400px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const MainContainer = styled("div")({
  width: "auto",
  marginTop: "-100px",
})

const LandingServicesContainer = styled(Paper)({
  padding: "10em 0em",
  clipPath: "polygon(0 15%, 100% 0, 100% 100%, 0% 100%)",
})

const ServicesTextContainer = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  color: "#fff",
  gap: "1em",
  [breakpoint("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
})

const ServicesCaption = styled("div")({
  display: "flex",
  gap: "1em",
  alignItems: "center",
})

const CaptionText = styled(Typography)({
  textTransform: "uppercase",
  letterSpacing: "0",
  [breakpoint("sm")]: {
    letterSpacing: "0.32em",
  },
})

const LandingServicesTitle = styled(Typography)({
  fontSize: "32px",
  [breakpoint("lg")]: {
    fontSize: "48px",
  },
})

const HomeServices = styled(MaxWidthContainer)({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",
  marginTop: "-120px",
  position: "relative",
  zIndex: 5,
  [breakpoint("sm")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
})

const CaseStudiesContainer = styled(MaxWidthContainer)({
  padding: "6em 2em",
  display: "flex",
  gap: "4em",
  flexDirection: "column",
  width: "auto",
})

const CaseStudiesTitle = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  color: theme.palette.darkblue.main,
}))

const InsightsSection = styled(Paper)({
  padding: "10em 2em",
  flexDirection: "column",
  gap: "4em",
})

const InsightsTitleSection = styled(MaxWidthContainer)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  flexDirection: "column",
  gap: "1em",
  padding: "0",
  [breakpoint("lg")]: {
    padding: "0 2em",
  },
  [breakpoint("sm")]: {
    flexDirection: "row",
    gap: "0",
  },
})

const InsightsTitle = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const InsightsContainer = styled(MaxWidthContainer)({
  display: "grid",
  padding: "0",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [breakpoint("lg")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})

const NewsletterSection = styled(MaxWidthContainer)({
  marginTop: "-120px",
  display: "flex",
  justifyContent: "center",
})
