import styled from "@emotion/styled"
import React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { PlainLink } from "../PlainLink/PlainLink"
import { Typography } from "../Typography/Typography"

interface ServicePreviewProps {
  icon: IGatsbyImageData
  title: string
  description: string
  link: string
  linkText: string
}
export const ServicePreview: React.FC<ServicePreviewProps> = ({
  icon,
  title,
  description,
  link,
  linkText,
}) => {
  return (
    <Card>
      <IconContainer>
        <GatsbyImage image={icon} alt={title} />
      </IconContainer>
      <ContentContainer>
        <Title variant="h5">{title}</Title>
        <Description variant="p">{description}</Description>
        <PlainLink to={link}>
          <LinkText variant="p">{linkText}</LinkText>
        </PlainLink>
      </ContentContainer>
    </Card>
  )
}

const Card = styled.div(({ theme }) => ({
  boxShadow: theme.boxShadow.light,
  borderRadius: "8px",
  display: "grid",
  gridTemplateRows: "1fr 2fr",
  padding: "20px",
  gap: "1em",
  backgroundColor: "#fff",
}))

const IconContainer = styled.div(({ theme }) => ({
  height: "64px",
  width: "64px",
  borderRadius: "50%",
  backgroundColor: `${theme.palette.secondary.main}10`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const ContentContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  maxWidth: "400px",
})

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkblue.main,
}))

const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontWeight: 700,
}))

const Description = styled(Typography)({
  lineHeight: "24px",
  textOverflow: "ellipsis",
  overflow: "hidden",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  lineClamp: 5,
  WebkitBoxOrient: "vertical",
})
