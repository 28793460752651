import { graphql } from "gatsby"
import { Home } from "../Home/Home"

export const query = graphql`
  query HomeQuery {
    homePage: markdownRemark(fileAbsolutePath: { regex: "/netlify/home.md/" }) {
      frontmatter {
        title
        subtitle
        buttonText
        landingImage {
          childImageSharp {
            gatsbyImageData(height: 450)
          }
        }
        landingServices {
          caption
          title
          serviceCard {
            icon {
              childImageSharp {
                gatsbyImageData
              }
            }
            title
            description
            link
          }
        }
        caseStudies {
          caption
          title
          featuredCaseStudies
        }
        insight {
          caption
          title
          featuredInsights
        }
        newsletter {
          sideImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }

    caseStudies: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/case_studies/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          caption
          client
          color
          coverImage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }

    insights: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/blog/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          category
          summary
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default Home
